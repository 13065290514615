.footer {
  font-size: 12px;
  background-color: var(--color-background-container-1);
  position: relative;
  width: 100%;
  border-radius: 24px 24px 0 0;
  padding: 20px 16px 32px 16px;
  margin-top: -40px;
  z-index: 10;

  @include breakpoint('tablet') {
    margin-top: 64px;
    background-color: var(--color-background-container-2);
    border-radius: 40px 40px 0 0;
    padding: 40px 32px 40px 32px;
  }
  &-assets {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &-links {
    display: flex;
    gap: 16px;
    list-style: none;
    padding: 8px 0;
    margin: 0;
    max-width: 70%;
    flex-wrap: wrap;
    a {
      display: block;
      color: var(--text-color);
    }
  }
  &-text {
    color: var(--text-color-low);
    margin: 24px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: 8px;
    svg {
      min-width: 40px;
      height: auto;
    }
  }

  &-logo {
    filter: grayscale(1);
  }
}
