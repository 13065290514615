.page-static {
  color: var(--text-primary);
  h3 {
    margin: 8px 0 16px;
  }
  .indent {
    padding: 8px 16px 16px;
  }
  ul {
    padding-left: 8px;
    margin-bottom: 16px;
    li {
      padding-left: 16px;
    }
  }
  strong {
    font-weight: 700;
  }
}