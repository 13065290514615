@import 'mixins/breakpoints';

body {

  --countdown-label-color: #afb4c0;

  // scrollbars
  --scrollbar-width: 8px;
  --scrollbar-background: var(--color-neutral-900);
  --scrollbar-background-hover: var(--color-neutral-600);


  // cookie consent
  --cookie-consent-background-color: white;
  --cookie-consent-color: #333;

  // error handling
  --color-error: #fb0035;
  --color-warning: #fec432;
  --color-info: #139eff;
  --color-success: #1ee663;

  // game voting
  --game-voting-star-color: #fff300;

  // content margins
  --margin-content-left: 0;
  --margin-content-top: 0;
  --content-container-position-left: 0;
  --content-container-wrapper-align: flex-start;

  // system colors
  --color-base-1: #ffffff;
  --color-base-2: #000000;
  --color-neutral-100: #f0f1f4;
  --color-neutral-200: #afb4c0;
  --color-neutral-300: #9aa0ac;
  --color-neutral-400: #868b98;
  --color-neutral-500: #717784;
  --color-neutral-600: #5c6270;
  --color-neutral-700: #484e5b;
  --color-neutral-800: #404654;
  --color-neutral-900: #373d49;
  --color-neutral-1000: #2c303a;
  --color-tickets: #a1fc4b;
  --color-coins: #ffd83b;
  --color-action-primary: #26e8a7;
  --color-accent-primary: #1ce0ff;
  --color-accent-secondary: #8b83f6;
  --color-icon-primary: var(--color-neutral-100);
  --color-icon-primary-inverse: var(--color-neutral-800);
  --color-icon-secondary: var(--color-neutral-200);
  --color-text-primary: var(--color-neutral-100);
  --color-text-primary-inverse: var(--color-neutral-800);
  --color-text-secondary: var(--color-neutral-200);
  --color-divider: rgba(240, 241, 244, 0.15);
  --color-rating: #fff300;
  --text-primary-inverse: var(--color-neutral-100);

  // background colors
  --color-background: var(--color-neutral-1000);
  --color-background-container-0: #2e333c;
  --color-background-container-1: var(--color-neutral-900);
  --color-background-container-2: var(--color-neutral-800);
  --color-background-container-3: var(--color-neutral-700);

  --primary-background: radial-gradient(circle at 0 0, #202023, #192241 29%, #2f276f 50%);

  // logo
  --logo-color: #ffffff;

  // header
  --header-height: 56px;
  --header-width: 100%;
  --header-background: var(--color-neutral-900);
  --header-position-left: 0;
  --header-background-image: linear-gradient(var(--color-neutral-800), var(--color-neutral-800), var(--color-neutral-800)) padding-box,
  linear-gradient(to right, var(--color-accent-primary), var(--color-action-primary)) border-box;

  // text colors
  --text-primary: var(--color-neutral-100);
  --text-secomdary: var(--color-neutral-200);

  --text-color: var(--text-primary);
  --text-color-low: var(--text-secomdary);
  --text-color-high: var(--text-primary);

  // other heights, padding and margins
  --padding: 16px;
  --border-radius: 24px;
  --box-padding: 24px;

  // buttons
  --button-background-color: #26e8a7;
  --button-background-color-disabled: var(--color-neutral-700);
  --button-text-color: var(--text-color-high);
  --button-text-color-dark: var(--color-neutral-900);
  --button-text-color-disabled: var(--color-neutral-500);
  --button-padding: 8px 16px;
  --button-ghost-padding: 6px 16px;
  --button-background-image: linear-gradient(113deg, #e262fb, #7988ff 87%);
  --button-font-size: 16px;
  --button-border-size: 0;
  --button-border-radius: 20px;
  --button-ghost-text-color: var(--button-background-color);
  --button-box-shadow: 0 0 16px 0 #b380fe, 0 8px 16px 0 rgba(142, 128, 254, 0.2);

  // profile image
  --profile-image-width: 136px;
  --profile-image-shine: linear-gradient(135deg, #1ce0ff 0%, #1f45ff);
  --profile-image-second-border: 7;
  --profile-image-border-size: 2px;

  // top container
  --top-container-border-radius: 0;
  --top-container-padding-top: var(--header-height);
  --top-container-margin-top: calc(-1 * var(--header-height));
  --top-container-raffle-margin-top: 72px;

  // navi block
  --navi-position-bottom: 0;
  --navi-position-top: auto;
  --navi-width: 100%;
  --navi-flex-wrap: auto;
  --navi-link-width: 20%;
  --navi-text-color: rgba(255, 255, 255, 1);
  --navi-background-color: var(--color-neutral-700);
  --navi-active-color: #26e8a7;
  --navi-active-svg-shadow: rgba(29, 233, 182, 0.5);
  --navi-auth-height: 68px;
  --navi-height: 64px;
  --navi-link-text-align: center;
  --navi-link-label-display: block;
  --navi-background-color-item: transparent;
  --navi-link-flex-wrap: wrap;
  --navi-margin-bottom: 0;
  --navi-link-border-radius: 0;
  --navi-link-padding: 8px 0;
  --navi-link-font-size: 12px;
  --navi-link-line-height: 1.3;
  --navi-link-text-padding-left: 0;

  // content
  --headline-height: 60px;
  --content-min-height: calc(100vh - var(--navi-height) - var(--navi-auth-height));
  --content-padding-top: var(--header-height);
  --content-background-image: none;
  --content-max-width: 960px;
  --content-margin: 0;
  --max-width-small-content: 368px;

  // container
  --container-min-height: calc(100vh - var(--header-height) - var(--navi-height) - var(--heading-height));
  --container-border-radius: var(--border-radius) var(--border-radius) 0 0;
  --container-background-border-radius: 0;
  --container-height: var(--container-min-height);
  --container-padding: 0 var(--padding) 0 var(--padding);
  --container-background-color: var(--color-background);
  --container-outline: var(--color-neutral-700);

  // page transition
  --page-transition-padding-top: var(--header-height);
  --page-transition-margin-top: 0;

  // animations
  --animation-transition-speed: 0.3s;
  --animation-new-page-forwards: mobilePageTransition var(--animation-transition-speed) ease;

  // display
  --display-desktop-upper: none;
  --display-tablet-upper: none;

  // popup-info
  --popup-info-blur: 10px;
  --popup-info-border-radius: var(--border-radius) var(--border-radius) 0 0;
  --popup-info-background: var(--color-background-container-1);
  --popup-info-position: absolute;
  --popup-info-position-bottom: 0;
  --popup-info-text-align: center;
  --popup-info-width: 100%;
  --popup-info-container-padding: calc(var(--padding) * 3) 0 var(--padding) 0;

  --popup-info-pug-display: flex;
  --popup-info-transition: height 0.5s ease-out;
  --popup-info-animation: openPopup 0.3s ease-out;
  --popup-info-button-close-display: none;

  // achievements
  --achievement-badge-background: var(--color-background-container-1);

  // bonus badge
  --bonus-badge-size: 64px;

  // notifications
  --notification-background: rgba(64, 70, 84, 0.75);

  // change size for tablet viewport

  @import 'vars-tablet';

  // change size for desktop viewport

  @import 'vars-desktop';
  @import 'vars-desktop-medium';
  @import 'vars-desktop-large';
}
