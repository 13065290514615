@import 'styles/reset';
@import 'styles/_vars.scss';
@import 'styles/_theme-light.scss';
@import 'styles/mixins/index.scss';

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-size: 18px;
  line-height: 1.5;
  background-color: var(--color-background);
  color: var(--text-color);
  overflow: hidden;
}

body * {
  box-sizing: border-box;
  user-select: none;

  &::-webkit-scrollbar {
    display: none !important;
  }

  @include breakpoint("desktop") {
    ::-webkit-scrollbar {
      display: block;
    }
  }
  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  ::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
    border-radius: var(--scrollbar-width);
    opacity: 0.3;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--color-neutral-600);
    border-radius: var(--scrollbar-width);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-background-hover);
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

a,
.link {
  text-decoration: none;
  color: var(--color-action-primary);
}

input,
button {
  font-size: 16px;
}

input,
button,
a {
  outline: none;
}

h1 {
  padding: 14px var(--padding);
  position: relative;
  z-index: 1;
  font-size: 24px;
  line-height: 1.17;
  text-align: left;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  width: 100%;

  &:first-of-type {
    // main headline margins
    margin-bottom: 16px;
    @include breakpoint('tablet') {
      padding: var(--padding) 0;
    }
  }
}

h2 {
  --heading-h2: #1ce0ff;
  font-size: 20px;
  text-transform: uppercase;
  padding: var(--padding) 0;
  line-height: 1.2;
  color: var(--heading-h2);
}

h3 {
  font-size: 16px;
  line-height: 1.5;
  @include breakpoint('tablet') {
    font-size: 21px;
  }
}

p {
  padding: 0 0 var(--padding) 0;
  line-height: 1.5;
  font-size: 16px;
}

@import './animation/ripple';
@import './animation/pageTransition';
@import './animation/effects';

@import './layout/index';

@import './element/button';
@import './element/forms';
@import './element/gamelist';
@import './element/inline-message';
@import './element/icon';

@import './pages/contact';
@import './pages/game';
@import './pages/games';
@import './pages/main';
@import './pages/static-page';
@import './style-zig';
