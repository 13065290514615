.contact-form {
  input {
    margin-bottom: 16px;
  }
  textarea {
    min-height: 200px;
    margin-bottom: 16px;
  }
  .inline-message {
    margin: 0 0 16px 0;
  }
}

.page-contact {
  max-width: 768px;
}