@import "../mixins/breakpoints";

.logo-wrapper {
  position: relative;
  svg {
    aspect-ratio: 104 / 40;
    height: 32px;
    width: auto;
    @include breakpoint('tablet') {
      height: 44px;
    }
  }
}

.color-coins {
  color: var(--color-coins);
}

.color-tickets {
  color: var(--color-tickets);
}

.hidden {
  display: none !important;
}

.desktop-upper {
  display: none;
  @include breakpoint('desktop') {
    display: initial;
  }
}

.fullwidth {
  width: 100vw;

  &--left {
    margin-left: -16px;
  }

  @include breakpoint('tablet') {
    width: 100%;
    &--left {
      margin-left: 0;
    }
  }
}

.mobile-only {
  @include breakpoint('tablet') {
    display: none !important;
  }
}

.tablet-upper {
  display: none;
  @include breakpoint('tablet') {
    display: block !important;
  }
}

.desktop-upper {
  display: none;
  @include breakpoint('desktop') {
    display: block !important;
  }
}

small {
  font-size: 12px;
  display: block;
  color: var(--text-color-low);
  margin: 8px 0 8px 0;
}

hr {
  border: 0;
  border-top: solid 1px var(--color-divider);
  margin: 0 0 var(--padding) 0;
}

.socas {
  margin: 0 auto;
  position: relative;
  height: 100vh;
  min-width: 320px;
}

body {
  transition: filter 0.25s ease;
}

body.popup-open {
  overflow: hidden;
  filter: blur(var(--popup-info-blur));
}

.background-desktop-primary {
  @include breakpoint('tablet') {
    padding: 32px;
    margin-bottom: 32px;
  }
}

// content, should be defined only once

.content {
  &-wrapper {
    position: relative;
    left: var(--content-container-position-left);
    width: calc(100vw - var(--content-container-position-left));
    display: flex;
    align-items: flex-start;
    justify-content: var(--content-container-wrapper-align);
    @include breakpoint('tablet') {
      left: 0;
      width: 100vw;
    }
    @include breakpoint('desktop-xxl') {
      position: absolute;
    }
  }

  position: relative;
  min-height: var(--content-min-height);
  background-image: var(--content-background-image);
  border-radius: 0;
  top: 0;
  margin: var(--content-margin);
  padding-top: var(--header-height);
  height: var(--content-min-height);
  overflow-x: hidden;
  overflow-y: auto;

  &-inner {
    max-width: var(--content-max-width);
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    min-height: calc(100vh - var(--header-height) - 64px);
    @include breakpoint('tablet') {
      min-height: calc(100vh - var(--header-height));
    }
  }

  @include breakpoint('tablet') {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    top: var(--header-height);
    padding-top: 0;
    padding-right: 32px;
    width: 100%;
    padding-left: var(--content-container-position-left);
    height: calc(100vh - var(--header-height));
  }

  @include breakpoint('desktop') {
    padding-right: 16px;
  }

  @include breakpoint('desktop-xxl') {
    max-width: none;
  }
}

[data-is-authenticated='true'] .content {
  --content-min-height: calc(100vh - var(--navi-height));
  @include breakpoint('tablet') {
    --content-min-height: calc(100vh - var(--header-height));
  }
}

[data-is-authenticated='false'] .content {
  @include breakpoint('tablet') {
    height: calc(100vh - var(--header-height) - var(--navi-auth-height));
  }
  @include breakpoint('desktop') {
    height: calc(100vh - var(--header-height));
  }
}

.content-back-link {
  position: absolute;
  margin-top: 10px;
  z-index: 10;
  color: var(--color-action-primary);
}

.header-back-link {
  margin-left: -8px;
  margin-top: -4px;
  display: flex;
  align-items: center;

  a {
    display: flex;
    cursor: pointer;
    color: var(--color-action-primary);
  }
}

// container, nested in .content

.container {
  width: 100%;
  position: relative;
  padding: var(--container-padding);
  background-color: var(--container-background-color);
  border-radius: var(--border-radius);

  &--background {
    border-radius: var(--container-background-border-radius) !important;
    position: relative;
    z-index: 2;
    background: 0;
    padding-top: var(--fixed-content-container-height-default) !important;
  }

  &--overflow {
    overflow: hidden;
  }

  &--nopadding {
    padding: 0 !important;
  }

  &--content {
    padding-top: 24px;
  }

  &--paddingBottom {
    padding-bottom: var(--navi-height) !important;
    min-height: calc(100vh - 330px);

    @include breakpoint('tablet') {
      padding-bottom: 0 !important;
      min-height: auto;
    }
  }

  &--paddingTop {
    padding-top: 24px;
  }
}

.content-bottom {
  border-radius: var(--border-radius);
  @include breakpoint('tablet') {
    overflow: hidden;
    padding: 0 var(--padding) var(--padding) var(--padding);
    background: var(--primary-background);
  }
}

.box {
  @include box;

  &.box--centered {
    text-align: center;
  }

  &.box--padding {
    padding: var(--box-padding);
  }
}

.error {
  color: var(--color-error) !important;
}

.infoText {
  padding: 0 var(--padding) 40px var(--padding);
  font-size: 16px;
  line-height: 24px;
  text-align: left;

  &--voting {
    padding-bottom: 16px !important;
    margin-top: 0 !important;
  }

  &__image {
    width: 80px;
    height: auto;
    margin: 16px auto 32px auto;
    display: block;
  }

  h3 {
    text-align: center;
    font-size: 20px;
    margin: 8px auto 32px;
  }

  @include breakpoint('tablet') {
    padding: 0 48px 32px 32px;
  }
}

.button-popup {
  width: auto;
  margin: 16px auto;
  display: inline-block;
  padding-left: 32px;
  padding-right: 32px;
}

.content-wrapper--error + .navi-authenticate-buttons,
.content-wrapper--login + .navi-authenticate-buttons,
.content-wrapper--register + .navi-authenticate-buttons {
  display: none;
  --navi-auth-height: 0;
}

.content-wrapper--error .content,
.content-wrapper--login .content,
.content-wrapper--register .content {
  height: calc(100vh - var(--header-height));
}

.link--block {
  display: block;
  border-radius: 16px;
  padding: 0 16px;
}
