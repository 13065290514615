.header {
  position: fixed;
  height: var(--header-height);
  width: calc(var(--header-width) + 2px);
  left: var(--header-position-left);
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 200;

  padding: var(--padding);

  @include breakpoint('tablet') {
    padding: var(--padding) var(--padding) var(--padding) 0;
  }

  @include breakpoint('desktop') {
    padding: calc(var(--padding) * 2);
  }

  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: var(--header-alpha, 0);

    background: var(--header-background-image);

    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 0 0 var(--border-radius) 0;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
    @include breakpoint('tablet') {
      display: none !important;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  &__logo {
    position: relative;
    a {
      color: var(--logo-color);
    }
    &-link {
      margin-left: 8px;
      @include breakpoint('tablet') {
        margin-left: 0;
      }
    }
  }
  &__tools {
    position: relative;
    justify-content: space-around;
    gap: 8px;
    display: flex;
  }
}
