[data-ripple-effect] {
  position: relative;
  overflow: hidden;

  -webkit-tap-highlight-color: transparent;
}

.ripple {
  width: 2px;
  height: 2px;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  animation: rippleEffect 0.5s ease-in-out;
  &--warning {
    background-color: rgba(255, 0, 0, 0.5);
  }
}

@keyframes rippleEffect {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(var(--scale));
  }
}
