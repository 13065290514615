@import 'mixins/breakpoints';

@include breakpoint('tablet') {
  // content margins
  --margin-content-left: 96px;
  --margin-content-top: 96px;
  --content-container-position-left: var(--margin-content-left);

  // header
  --header-position-left: var(--margin-content-left);
  --header-width: calc(100vw - var(--margin-content-left) - var(--padding));
  --header-height: 88px;
  --header-background: var(--color-background-container-1);

  // top-container
  --top-container-border-radius: var(--border-radius) var(--border-radius) 0 0;
  --top-container-margin-top: calc(-1 * var(--padding));
  --top-container-raffle-margin-top: 8px;

  // navi
  --navi-background-color: var(--color-background-container-1);
  --navi-position-bottom: auto;
  --navi-position-top: var(--margin-content-top);
  --navi-width: var(--margin-content-left);
  --navi-flex-wrap: wrap;
  --navi-link-width: 100%;
  --navi-link-border-radius: 64px;

  // content block
  --content-min-height: auto;
  --content-padding-top: var(--padding);

  // achievements
  --achievement-badge-background: var(--color-background-container-1);

  // content container
  --container-height: calc(100vh - var(--header-height));
  --container-background-color: transparent;
  --container-padding: 0;

  // display
  --display-tablet-upper: block;

  // page transition
  --page-transition-padding-top: 0;
  --page-transition-margin-top: var(--header-height);

  // popup-info
  --popup-info-border-radius: var(--border-radius);
  --popup-info-position: static;
  --popup-info-pug-display: none;
  --popup-info-position-bottom: auto;
  --popup-info-text-align: left;
  --popup-info-width: 580px;
  --popup-info-button-close-display: block;
  --popup-info-transition: height 0.001s;
  --popup-info-animation: flipIn 0.3s ease-out;
  --popup-info-container-padding: var(--padding) 0 var(--padding) 0;

  // header
  --header-alpha: 0 !important;
}
