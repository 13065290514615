.page-games {
  h2 {
    padding: 24px 0 16px;
  }
}

.games {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.games__coinbadge {
  position: absolute;
  right: 8px;
  top: 8px;
}

.games__hero {
  color: var(--text-color-high);
  display: flex;
  flex-flow: column;
  gap: 8px;

  &:active,
  &:visited {
    color: var(--text-color-high);
  }

  &-thumb {
    border-radius: 16px;
    background-color: var(--color-background-container-3);
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;

    img {
      object-fit: cover;
      object-position: left top;
      width: 100%;
      height: 100%;
    }
  }
  &-name {
    font-size: 14px;
    line-height: 1.43;
    font-weight: 600;
  }
}

.games__hero-chance {
  background-color: var(--color-background);
  position: absolute;
  z-index: 4;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  bottom: 0;
  width: 200px;
  text-align: center;
  left: calc(50% - 100px);
  padding: 4px 16px 0;
  border-radius: 8px 8px 0 0;
}
