.icon {
  --icon-width: 33px;
  display: inline-block;
  width: var(--icon-width);
  height: var(--icon-width);
  background-repeat: no-repeat;
  background-size: cover;

  &--animated {
    position: fixed;
    z-index: 201;
    animation: topAnimation var(--top-animation-duration, 1s) ease-in-out;
    animation-delay: var(--top-animation-delay);
  }
  &--info {
    background-image: url('../images/icons/icon-info.svg');
    body.theme-light & {
      background-image: url('../images/icons/icon-info-light.svg');
    }
  }
  &--up {
    background-image: url('../images/icons/icon-expand-more.svg');
  }
  &--down {
    background-image: url('../images/icons/icon-expand-less.svg');
  }

  &--submenu {
    background-image: url('../images/icons/icon-more-vert.svg');
    --icon-width: 24px;
    position: absolute;
    right: 8px;
    top: 12px;
    body.theme-light & {
      background-image: url('../images/icons/icon-more-vert-light.svg');
    }
  }

  &--close {
    background-image: url('../images/icons/icon-close-light.svg');
    cursor: pointer;
    --icon-width: 24px;
  }

  &--ticket {
    background-image: url('../images/icons/icon-ticket.png');
    background-repeat: no-repeat;
    --icon-width: 22px;
  }

  &--fullscreen-recover {
    background-image: url('../images/icons/icon-fullscreen-1.svg');
    body.theme-light & {
      background-image: url('../images/icons/icon-fullscreen-1-light.svg');
    }
  }

  &--fullscreen-exit {
    background-image: url('../images/icons/icon-fullscreen-2.svg');
    body.theme-light & {
      background-image: url('../images/icons/icon-fullscreen-2-light.svg');
    }
  }

  &--coin {
    --icon-width: 21px;
    background-image: url('../images/icons/icon-coin.png');
  }
  &--clickable {
    cursor: pointer;
    border-radius: 50%;
    background-color: transparent !important;
    width: 40px;
    height: 40px;
    background-size: var(--icon-width);
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.icon--leftAligned {
      margin-left: calc(-1 * (40px - var(--icon-width)));
    }
  }
}

@keyframes topAnimation {
  0% {
    left: var(--top-animation-left-start);
    top: var(--top-animation-top-start);
    transform-origin: left top;
    scale: 1.2;
    rotate: 0deg;
    translate: 0;
  }
  30% {
    scale: var(--top-animation-30-scale, 2);
    translate: var(--top-animation-30-translate-x) var(--top-animation-30-translate-y);
  }
  60% {
    scale: var(--top-animation-60-scale, 0.8);
    translate: var(--top-animation-60-translate-x) var(--top-animation-60-translate-y);
  }
  100% {
    left: var(--top-animation-left-end);
    top: var(--top-animation-top-end);
    scale: 1;
    rotate: var(--top-animation-100-rotate, 160deg);
    transform-origin: center;
    translate: 0;
  }
}
