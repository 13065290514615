fieldset {
  border: 0;
  padding: 0;
  margin-bottom: calc(var(--padding) * 2);
  position: relative;
  display: block;
  &.margin-bottom {
    margin-bottom: calc(var(--padding) * 3);
  }
  small {
    margin: 4px 0 0 16px;
  }
  legend {
    font-size: 12px;
    line-height: 1.33;
    color: var(--text-secomdary);
    padding: 0 4px;
    display: inline-block;
    background: var(--color-background);
    position: absolute;
    left: 12px;
    top: -8px;
    z-index: 1;
  }
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px var(--color-neutral-500);
  height: 40px;
  background: transparent;
  width: 100%;
  color: var(--color-text-primary);
  font-size: 16px;
  font-family: inherit;
  &.error {
    border: solid 1px var(--color-error);
    & + .error-text {
      display: block;
    }
  }
  &:focus {
    border: solid 1px var(--color-action-primary);
  }
}

fieldset .error-text {
  position: absolute;
  width: 100%;
  padding: 4px 16px;
  color: var(--text-color-high);
  background-color: var(--color-error);
  font-size: 12px;
  border-radius: 4px;
  left: 0;
  top: 40px;
  display: none;
}

.form-control {
  display: flex;
  gap: 0.5em;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding);
  cursor: pointer;
  border: solid 2px transparent;
  border-radius: 16px;
  &--newsletter {
    > span {
      max-width: 260px;
    }
  }
  &--error {
    border-color: var(--color-error);
  }
  &--cb-left {
    align-items: flex-start;
    padding: var(--padding) 0;
    input[type='checkbox'] {
      min-width: 24px;
      margin-right: 8px;
    }
  }
}

input[type='radio'] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  width: 24px;
  height: 24px;
  background-image: url('../images/icons/icon-radio-button-unchecked.svg');
  position: relative;
  &:checked {
    background-image: url('../images/icons/icon-radio-button-checked.svg');
  }
}

input[type='checkbox'] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url('../images/icons/icon-check-box-unchecked.svg');
  position: relative;
  &:checked {
    background-image: url('../images/icons/icon-check-box-checked.svg');
  }
}

// avoid webkit autofill background color
// hack from https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

@include breakpoint('tablet') {
  .form-wrapper {
    max-width: var(--max-width-small-content);
    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .button--secondary {
      width: auto;
      padding: 8px 32px;
    }
  }
}
