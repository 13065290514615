body.theme-light {
  // system colors
  --color-base-1: #000000;
  --color-base-2: #FFFFFF;

  --color-neutral-1000: #383842;
  --color-neutral-900: #57576e;
  --color-neutral-800: #757599;
  --color-neutral-700: #9696b5;
  --color-neutral-600: #b7b7d2;
  --color-neutral-500: #d8d8ee;
  --color-neutral-400: #e1e1f1;
  --color-neutral-300: #ebebf5;
  --color-neutral-200: #f1f1f9;
  --color-neutral-100: #f8f8fc;

  --color-background: var(--color-neutral-200);
  --primary-background: radial-gradient(circle at 0 0, #eedef9, #d3d4f2 100%);
  --logo-color: #000000;

  --text-primary: #57576e;
  --text-primary-inverse: #f8f8fc;
  --text-secomdary: #757599;

  --color-icon-primary: #57576e;
  --color-icon-primary-inverse: #f8f8fc;
  --color-icon-secondary: #757599;

  --navi-text-color: var(--text-secomdary);

  --color-tickets: #79de1a;
  --color-coins: #ffd83b;

  --color-action-primary: #0f7be4;
  --color-accent-primary: #00c2e0;
  --color-accent-secondary: #8b83f6;
  --color-divider: rgba(87, 87, 110, 0.2);
  --color-rating: #fff300;
  --color-text-primary: var(--color-text);
  --color-background-container-0: var(--color-neutral-200);
  --color-background-container-1: #ffffff;
  --color-background-container-2: var(--color-neutral-100);
  --color-background-container-3: var(--color-neutral-300);

  --container-outline: var(--color-neutral-500);

  --header-background-image: linear-gradient(var(--color-neutral-200), var(--color-neutral-200), var(--color-neutral-200)) padding-box,
  linear-gradient(to right, var(--color-action-primary), var(--color-accent-primary)) border-box;

  --popup-info-background: #ffffff;
  --navi-background-color: var(--color-background-container-3);
  --navi-active-color: var(--color-action-primary);

  --button-text-color: #f8f8fc;
  --button-text-color-dark: var(--color-base-2);
  --button-text-color-disabled: var(--color-background-container-1);

  --button-background-color: var(--color-action-primary);

  --achievement-badge-background: var(--color-background-container-1);

  // error handling
  --color-error: #ff3d00;
  --color-warning: #ffbc00;
  --color-info: #00b0ff;
  --color-success: #00c853;

  // scrollbars
  --scrollbar-background: var(--color-neutral-100);
  --scrollbar-background-hover: var(--color-neutral-300);

  // notifications
  --notification-background: rgba(235, 235, 245, 0.75);

  .notification .icon--close {
    background-image: url('../images/icons/icon-close-dark.svg');
  }

  fieldset {
    legend {
      background: #DCDAF5;
    }
    .error-text {
      color: var(--text-primary-inverse);
    }
  }

  input[type='radio'] {
    background-image: url('../images/icons/icon-radio-button-unchecked-light.svg');

    &:checked {
      background-image: url('../images/icons/icon-radio-button-checked-light.svg');
    }
  }

  input[type='checkbox'] {
    background-image: url('../images/icons/icon-check-box-unchecked-light.svg');

    &:checked {
      background-image: url('../images/icons/icon-check-box-checked-light.svg');
    }
  }

  input[type='text'], input[type='password'], input[type='email'] {
    border: solid 1px var(--color-neutral-600);
  }

  .progress-bar {
    background-color: var(--color-background-container-3);

    .progress-bar__progress {
      background-color: var(--color-accent-secondary);
      background-image: none;
    }
  }

  .voting-wrapper {
    background-color: var(--color-background-container-2);
  }

  .page-index .raffle-container {
    color: var(--color-neutral-1000);
  }

  .navi a:not(.navi-link--active) svg {
    color: var(--text-primary);
  }

  .coloredHeadline {
    background-image: linear-gradient(to right, var(--color-action-primary), var(--color-accent-primary));
  }

  h2 {
    color: var(--color-accent-primary);
  }

  .game-list__item {
    background-image: url('../images/loader-light.svg');

    .name {
      color: var(--color-neutral-1000);
    }
  }

  .authenticate-btn {
    background-color: var(--color-neutral-100);
    border-color: var(--color-neutral-100);

    &--border {
      border-color: var(--color-action-primary);
    }
  }

  .button--tertiary {
    color: var(--color-text-primary);
    border: solid 2px var(--color-neutral-300);
    background-color: var(--color-neutral-300);
  }

  .game-wrapper--portrait {
    background-color: #000;
  }

  .ripple {
    background-color: rgba(150, 150, 181, 0.5);
  }

  .logo-wrapper {
    svg {
      display: none;
    }
    background-image: url('../images/operator/socas/logo-light.svg');
    background-repeat: no-repeat;
    background-position: left top;
    height: 32px;
    min-width: 85px;
    @include breakpoint('tablet') {
      min-width: 115px;
      height: 44px;
    }
  }
}
