@import 'mixins/breakpoints';

@include breakpoint('desktop') {
  // display
  --display-desktop-upper: block;

  // content margins
  --margin-content-left: 240px;
  --margin-content-top: 72px;

  // header
  --header-position-left: 0;
  --header-width: 100%;

  // navi
  --navi-background-color-item: var(--color-background-container-2);
  --navi-link-width: 100%;
  --navi-link-flex-wrap: nowrap;
  --navi-margin-bottom: var(--padding);
  --navi-link-border-radius: 50px;
  --navi-link-padding: calc(var(--padding) / 2) var(--padding);
  --navi-link-label-display: block;
  --navi-link-font-size: 16px;
  --navi-link-line-height: 1.5;
  --navi-link-text-align: left;
  --navi-link-text-padding-left: var(--padding);
}
