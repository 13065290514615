.effect-appear-gamebutton {
  animation: blurIn 0.5s ease-out;
}

.effect-disappear-gamebutton {
  animation: blurOut 0.5s ease-out forwards;
  pointer-events: none;
}

@keyframes blurOut {
  0% {
    filter: blur(0);
    opacity: 1;
  }
  100% {
    filter: blur(10px);
    opacity: 0;
  }
}
