body.fake-fullscreen,
.operator-zig body.fake-fullscreen {
  .container {
    position: static;
  }

  background-color: #000000 !important;

  .content,
  .container {
    margin: 0;
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
  }

  .content-inner:before,
  .content-top,
  .game-description,
  .game-caption,
  .game-help__more,
  .content-back-link,
  .backgroundAnim {
    display: none !important;
  }

  .container {
    background: transparent;
  }

  .game-wrapper {
    margin: 0;
  }

  .header,
  .footer,
  .navi {
    display: none !important;
  }

  .fullscreen-menu {
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: auto;
    border-radius: 32px !important;
    padding: 8px;
    justify-content: flex-end;
    margin-top: 0;
  }

  .fullscreen-menu__recover {
    display: none;
  }

  .fullscreen-menu__exit {
    display: block;
  }
}

.fullscreen-menu {
  background-color: var(--color-background-container-1);
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  cursor: var(--text-color);
  font-size: 14px;
  display: none;
  width: 100%;
  border-radius: var(--border-radius);
  margin-top: 16px;

  .game-wrapper:fullscreen & {
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: auto;
    padding: 8px;
    justify-content: flex-end;
    margin-top: 0;
    border-radius: 64px;
    display: none; // real fullscreen apps should provide their own exit button
  }

  &--touched {
    display: flex;
  }

  .fullscreen-menu__recover {
    display: flex;
    align-items: center;
    gap: 4px;

    .game-wrapper:fullscreen & {
      display: none;
    }
  }

  .fullscreen-menu__exit {
    display: none;
    gap: 4px;

    .game-wrapper:fullscreen & {
      display: flex;
    }
  }
}

.fake-fullscreen .game-wrapper {
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 300;
  border-radius: 0 !important;
}

.rotated {
  transform: rotate(-90deg) !important;
}

.game {
  display: block;
  padding: 0;

  &-voting-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    --color-background-container-3: transparent;

    .voting {
      --icon-width: 40px;
      margin: 0 auto 4px;
    }

    strong {
      display: block;
      width: 100%;
      font-weight: 700;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    .button {
      margin: 16px 0;
    }
  }

  &-wrapper {

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 100vw;
    margin-left: -16px;

    @include breakpoint('tablet') {
      margin-left: 0;
      width: 100%;
    }

    &--portrait {
      aspect-ratio: 16 / 9;
      border-radius: var(--border-radius);
      background-color: var(--color-background-container-1);
    }

    &:fullscreen {
      height: 100%;
      width: 100vw;
      border-radius: 0 !important;

      .game-frame {
        width: auto;
        height: auto;
      }
    }

    @include breakpoint('tablet') {
      border-radius: var(--border-radius);
      overflow: hidden;
    }
  }

  &-description {
    margin: 32px 0;
    font-size: 16px;
    line-height: 1.5;

    > span:first-child,
    .flow-title {
      display: block;
      text-transform: uppercase;
      margin: var(--padding) 0;
      font-weight: 700;
    }
  }

  &-caption {

    @include breakpoint('tablet') {
      .inline-message {
        margin-top: 16px;
        margin-left: 16px;
      }
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .button {
        width: auto;
        min-width: 34%;
        margin-top: 16px;
      }
    }
    @include breakpoint('tablet-large') {
      .button {
        max-width: 240px;
      }
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 16px 0;
    gap: 16px;
    @include breakpoint('tablet') {
      width: 310px;
    }

    small {
      text-align: center;
      white-space: nowrap;
    }

    counter-badge {
      display: block;
    }
  }

  &-frame {
    display: block;
    height: auto;
    width: 100%;
    aspect-ratio: 16 / 9;
    transform: rotate(0deg);
    transition: transform 0.5s ease;

    .game-wrapper--portrait & {
      aspect-ratio: 9 / 16;
      height: 100%;
      width: auto;
    }
  }
}

.game-current-voting {
  cursor: pointer;

  small {
    color: var(--color-action-primary);
  }
}

.game-help {
  margin-bottom: 32px;
  color: var(--text-color-low);
  text-align: left;
  padding: 0 32px;
  max-height: 70vh;
  overflow: auto;

  h1 {
    margin-bottom: 0;
    color: var(--text-color-high);
  }

  h1,
  h2,
  h3 {
    margin-left: 0 !important;
    padding-left: 0;
    font-size: 20px;
    text-transform: none;
  }

  &__more {
    cursor: pointer;
    margin-bottom: 20px;
    display: block;
  }
}

.voted {
  display: none;
}

.voted-by-player {
  .voted {
    display: inline-block;
  }

  .not-voted {
    display: none;
  }
}

.game-xxl {
  display: none;
  @include breakpoint('desktop-large') {
    display: flex;
    margin-left: 8px;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
  }

  .exit {
    display: none;
  }
}

.game-wrapper {
  @include breakpoint('desktop-large') {
    transition: all 0.4s ease;
  }
}

.game-feature-xxl {

  @include breakpoint('desktop-large') {

    .footer {
      z-index: 0;
    }
    .container--overflow {
      overflow: visible;
    }
    .content-back-link {
      z-index: 2;
    }
    .game-section {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 2;
      backdrop-filter: blur(var(--popup-info-blur));

      padding: var(--header-height) 32px 200px var(--margin-content-left);

      .game-wrapper {
        border-radius: 0;
        max-height: calc(100vh - 200px);
      }

      .game-frame {
        max-height: calc(100vh - 200px);
        height: calc(100vh - 200px);
        width: auto;
        animation: blurInFast 0.3s ease forwards;
        max-width: 100%;
      }

      .game-caption {
        max-width: 790px;
        margin: 0 auto;
        padding-top: 16px;
      }

      .game-xxl {
        .exit {
          display: block;
        }

        .recover {
          display: none;
        }
      }
    }
  }
}

@keyframes blurInFast {
  0% {
    filter: blur(40px);
  }
  100% {
    filter: blur(0);
  }
}

.game-ripple {
  border-radius: var(--border-radius);
}