@import 'styles/_vars.scss';
@import 'styles/mixins/index.scss';

@font-face {
  font-family: Metric;
  font-display: swap;
  src: url("../images/fonts/metric/Metric-Regular.otf") format("opentype");
}

@font-face {
  font-family: Metric;
  font-weight: bold;
  font-display: swap;
  src: url("../images/fonts/metric/Metric-Bold.otf") format("opentype");
}

.operator-zig {
  .theme-light {
    --text-primary: #005578 !important;
    --color-action-primary: #00b4c8 !important;
    --color-accent-primary: #004155 !important;

    --color-info: #FFc073 !important;
    --color-error: #FF6464 !important;
    --color-success: #FF915a !important;
    --color-warning: #A082d2 !important;
    --navi-auth-height: 0px !important;
    --border-radius: 8px !important;
    --color-background: #ffffff !important;
    --navi-link-font-size: 16px;

    --footer-height: 590px;

    @include breakpoint("tablet") {
      --navi-link-font-size: 14px;
      --footer-height: 590px;
    }
    @include breakpoint("desktop") {
      --navi-link-font-size: 18px;
      --navi-width: 192px;
      --footer-height: 600px;
    }
    @include breakpoint("desktop-xxl") {
      --navi-link-font-size: 18px;
      --navi-width: 240px;
      --footer-height: 700px;
    }
  }

  body {
    font-family: Metric, SansSerif, sans-serif;
    background: #FFFFFF !important;

    &::-webkit-scrollbar {
      display: block !important;
    }

    @include breakpoint("desktop") {
      &:before {
        content: "";
        width: 100vw;
        height: 10vw;
        position: absolute;
        background-image: url(../images/operator/zig/zeal-blue.svg) !important;
        background-repeat: no-repeat !important;
        background-position: center top !important;
        background-size: 100% auto;
        transform: rotate(180deg);
        top: 0;
        left: 0;
      }
    }
  }

  input,
  button {
    font-family: Metric, sans-serif;
  }

  .logo-wrapper {
    background-image: none !important;
    min-width: auto !important;
    height: auto !important;

    svg {
      aspect-ratio: 16 / 6;
      height: 45px;
      display: inline-block;
      position: relative;
      top: 2px;
      transition: all 0.5s ease;
      @include breakpoint('desktop') {
        height: 50px;
        top: 8px;
      }
      @include breakpoint('desktop-xxl') {
        height: 60px;
        top: 8px;
      }
    }
  }

  body.theme-light .logo-wrapper svg {
    display: inline-block;
  }

  .zig-info {
    background-color: rgba(255, 255, 255, 0.1);
    padding: var(--padding);
    border-radius: var(--border-radius);
    margin-bottom: var(--padding);

    @include breakpoint('tablet') {
      padding: 0;
    }
  }

  .zig-intro {
    background-image: url('../images/background/devices.png');
    background-repeat: no-repeat;
    background-position: center 0;

    background-size: 310px auto;
    padding: 160px var(--padding) var(--padding) var(--padding);

    @include breakpoint('tablet') {
      padding-left: 0;
    }
    @include breakpoint('desktop') {
      padding: 0 330px 0 0;
      background-position: top right;
      min-height: 150px;
      font-size: 18px;
    }
    @include breakpoint('desktop-large') {
      min-height: 230px;
      background-size: 440px auto;
      font-size: 21px;
      padding: 0 430px 0 0;
    }
    @include breakpoint('desktop-xxl') {
      min-height: 330px;
      padding: 0 500px 0 0;
      background-size: 740px auto;
      font-size: 26px;
    }
  }

  .backgroundAnim {
    display: none !important;
  }

  h1, h2, .coloredHeadline {
    color: var(--text-primary) !important;
    background-image: none !important;
    background-clip: initial !important;
    -webkit-background-clip: initial !important;
    -webkit-text-fill-color: initial !important;
  }

  @include breakpoint("tablet") {
    .content--subpage h1:first-of-type {
      margin-left: 40px;
      width: calc(100% - 48px);
    }
  }

  .fullscreen-menu {
    font-size: 18px;
  }

  .fullscreen-menu-text-link {
    margin-top: 4px;
  }

  .navi-authenticate-buttons {
    display: none !important;
  }

  .navi a {
    strong {
      @include breakpoint("desktop") {
        margin-top: 8px;
      }
    }
  }

  .games__hero-thumb,
  .game-list__item img {
    border-radius: var(--border-radius);
  }

  .game-list__item .coinbadge,
  .games__coinbadge {
    display: none;
  }

  .game-list__item .name,
  .games__hero-name {
    font-size: 16px;
    font-weight: normal;
  }

  .games__hero-name {
    font-size: 18px;
  }

  .navi {
    mix-blend-mode: luminosity;

    a {
      @include breakpoint("tablet") {
        background: #fff;
      }
    }
  }

  textarea {
    resize: none !important;
  }

  .footer {
    border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
    padding: 16px 24px;
    background-color: transparent !important;
    font-size: 14px;
    background-image: url(../images/operator/zig/zeal-orange.svg) !important;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    color: #fff;
    height: var(--footer-height);

    &-links {
      font-size: 16px;
      padding-top: 14px;
      padding-right: 16px;
      @include breakpoint("desktop-xxl") {
        padding-top: 20px;
      }
    }

    &-assets {
      justify-content: flex-end;

    }

    .logo-wrapper svg {
      filter: drop-shadow(0 0px 13px rgba(255, 255, 255, 0.9));
      @include breakpoint('desktop') {
        top: 0;
      }
    }

    &-text {
      color: #fff;
      flex-flow: column;

      a {
        color: #fff;
        text-decoration: underline;
      }

      div {
        margin-bottom: 16px;
        text-align: center;
      }
    }

    &-imprint {
      width: 300px;
      margin: 0 auto;
      text-align: center;
      padding-top: 24px;
      @include breakpoint("desktop-small") {
        padding-top: 6vw;
      }
    }

    .logo-wrapper {
      opacity: 0.75;
    }
  }

  .header {
    width: 100vw !important;
    left: 0 !important;

    @include breakpoint("tablet") {
      padding-left: 96px;
      &:before {
        content: "";
        width: 100vw;
        height: 200px;
        position: absolute;
        background-image: url(../images/operator/zig/zeal-blue.svg) !important;
        background-repeat: no-repeat !important;
        background-position: left top !important;
        background-size: 100% auto;
        transform: rotate(180deg);
        bottom: 0;
        left: 0;
      }
    }
    @include breakpoint("desktop") {
      padding-left: 32px;
      &:before {
        display: none;
      }
    }
  }

  .fullscreen-menu {
    border-radius: 0;
    margin-top: 0;
  }

  .content {
    padding-left: 0 !important;
    padding-right: 0 !important;

    &-inner {
      max-width: 1400px;
      margin: 0 auto 0 auto;
      position: relative;
      min-height: calc(100vh - var(--footer-height) - var(--header-height) - 64px);
      height: auto;

      @include breakpoint('tablet') {
        padding-right: var(--padding);
        padding-left: var(--navi-width);
      }

      &:before {
        content: "";
        width: 100vw;
        height: 200px;
        position: absolute;
        background-image: url(../images/operator/zig/zeal-blue.svg) !important;
        background-repeat: no-repeat !important;
        background-position: left top !important;
        background-size: 100% auto;
        transform: rotate(180deg);
        top: -160px;

        @include breakpoint("tablet") {
          background-image: none !important;
        }
      }
    }

    @include breakpoint('desktop') {
      height: 100vh;
      top: 0;
      padding-top: var(--header-height);
    }
  }

  @include breakpoint("desktop") {
    .newPageTransition {
      margin-top: 0 !important;
    }

    .oldPageTransition {
      margin-top: 0;
    }
  }

  .container--paddingBottom {
    min-height: auto;
  }

  .zig-message {
    border-radius: var(--border-radius);
    font-size: 18px;
    background-color: #09728E;
    color: #fff;
    height: 100%;
    text-align: center;
    padding: 16px;
    hyphens: auto;

    h4 {
      margin-bottom: 8px;
    }

    .zig-icon {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      background-color: #00BEC3;
      margin: 0 auto 16px auto;

      img {
        min-width: 40px;
        height: auto;
        display: block;
      }
    }

    &--secondary {
      background-color: var(--color-error);

      .zig-icon {
        background-color: #FFFFFF;
      }
    }
  }


  .header__background {
    border-right: 0;
    border-radius: 0;
  }

  .game-list {
    @include breakpoint("desktop-large") {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include breakpoint("desktop-xxl") {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  .button {
    padding: 12px 0 5px 0;
    font-size: 18px;
    border-radius: 50px;
  }

  .game-description,
  .game-help__more {
    font-size: 18px;
  }

  input[type="text"] {
    padding: 8px 16px 4px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  .header {
    z-index: 20000;
    @include breakpoint("desktop") {
      width: 230px !important;
    }
  }

  p {
    font-size: 18px;
  }

  .games__hero-chance {
    display: none;
  }

  body * {
    @include breakpoint("tablet") {
      ::-webkit-scrollbar {
        display: block !important;
      }
    }
  }
}

.contactBtn {
  @include breakpoint("tablet") {
    border-radius: var(--border-radius) !important;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.two-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  @include breakpoint('tablet') {
    grid-template-columns: 1fr 1fr;
  }
  @include breakpoint('desktop-large') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.zig-games {
  margin-bottom: var(--padding);
  .game-list {
    @include breakpoint("desktop-large") {
      .game-list__item:last-child {
        display: none;
      }
      .game-list__item:nth-child(-n + 1) {
        display: none;
      }
    }
    @include breakpoint("desktop-xxl") {
      .game-list__item:last-child {
        display: flex;
      }
    }
  }
}

.games-filter {
  display: flex;
  gap: 16px;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 40px 0;

  a {
    color: var(--text-primary);

    &:hover,
    &:active {
      text-decoration: underline;
    }

    &.selected {
      text-decoration: underline;
    }
  }
}

.centered {
  text-align: center;
}

.headline-with-link {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.moreLink {
  text-transform: none;
  font-weight: normal;
  font-size: 16px;
  color: var(--color-action-primary) !important;
  display: block;
}
