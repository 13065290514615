.navi {
  background-color: var(--navi-background-color);
  top: var(--navi-position-top);
  bottom: var(--navi-position-bottom);
  height: var(--navi-height);
  color: var(--navi-text-color);
  width: var(--navi-width);
  flex-wrap: var(--navi-flex-wrap);
  position: fixed;
  left: 0;
  display: flex;
  z-index: 201;
  min-width: 320px;

  @include breakpoint('tablet') {
    justify-content: center;
    min-width: auto;
    background-color: transparent;
    max-height: calc(100vh - var(--header-height));
    height: auto;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  @include breakpoint('desktop') {
    margin-top: 8px;
    padding: var(--padding);
    padding-right: 32px;
  }

  svg {
    display: block;
  }

  hr.desktop-upper {
    margin: 0 16px 16px;
  }
  a,
  a:visited,
  a:active,
  a:hover {
    color: var(--navi-text-color);
    text-decoration: none;
  }

  a {
    width: var(--navi-link-width);
    display: flex;
    flex-wrap: var(--navi-link-flex-wrap);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    color: var(--navi-text-color);
    padding: var(--navi-link-padding);
    margin-bottom: var(--navi-margin-bottom);
    border-radius: var(--navi-link-border-radius);
    @include breakpoint('tablet') {
      height: 64px;
      width: 64px;
    }
    @include breakpoint('desktop') {
      height: 40px;
      width: 100%;
    }

    strong {
      display: var(--navi-link-label-display);
      font-size: var(--navi-link-font-size);
      text-align: var(--navi-link-text-align);
      padding-left: var(--navi-link-text-padding-left);
      line-height: var(--navi-link-line-height);
      text-transform: none;
      width: 100%;
    }

    &:hover,
    &:active {
      color: var(--navi-text-color);
      opacity: 1;
      background-color: var(--navi-background-color-item);
      @include breakpoint('desktop') {
        background: rgba(0, 0, 0, 0.5);
        .theme-light & {
          background: rgba(255, 255, 255, 0.75);
        }
      }
    }
  }

  a.navi-link--active {
    color: var(--navi-active-color);
    text-shadow: 0 2px 12px rgba(29, 233, 182, 0.5);
    background-color: var(--navi-background-color-item);

    @include breakpoint('desktop') {
      text-shadow: none;
      background: rgba(0, 0, 0, 0.5);
      .theme-light & {
        background: rgba(255, 255, 255, 0.75);
      }
    }

    span {
      color: var(--navi-active-color);
    }

    svg {
      filter: drop-shadow(0 2px 12px var(--navi-active-svg-shadow));
      @include breakpoint('desktop') {
        filter: none;
      }
    }
  }

  &-link {
    width: 100%;
  }
}

.navi-authenticate-buttons {
  background-color: var(--color-background-container-3);
  padding: 12px 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: var(--navi-height);
  width: 100%;
  z-index: 201;
  height: var(--navi-auth-height);
  @include breakpoint('tablet') {
    bottom: 0;
    justify-content: center;
    .button {
      max-width: 162px;
    }
  }
  @include breakpoint('desktop') {
    bottom: auto;
    left: auto;
    top: 8px;
    right: 162px;
    background-color: transparent;
    width: 320px;
    padding: 0;
  }

  [data-is-authenticated='true'] & {
    display: none;
  }
}
