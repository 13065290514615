.newPageTransition,
.oldPageTransition {
  --max-width: 100vw;
}

.newPageTransition {
  position: absolute !important;
  z-index: 200;
  top: 0 !important;
  background: var(--primary-background);
  animation: var(--animation-new-page-forwards);
  padding-top: var(--page-transition-padding-top);
  margin-top: var(--page-transition-margin-top) !important;
  max-width: var(--max-width) !important;
}

.oldPageTransition {
  position: absolute !important;
  z-index: 200;
  margin-top: var(--header-height);
  animation: fadeOut var(--animation-transition-speed) ease forwards;
  max-width: var(--max-width) !important;
}

@keyframes blurIn {
  0% {
    filter: blur(20px);
  }
  100% {
    filter: blur(0);
  }
}

@keyframes mobilePageTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
