.button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  z-index: 10;

  &--alignRight {
    justify-content: flex-end;
  }

  &--padding {
    padding: var(--padding) var(--padding) calc(var(--padding) / 2) var(--padding);
  }

  &--margin-bottom {
    margin-bottom: var(--navi-height);
  }
}

.button {
  border-radius: var(--button-border-radius);

  background-image: var(--button-background-image);
  box-shadow: 0 8px 16px 0 rgba(142, 128, 254, 0.4);

  color: var(--button-text-color);
  font-size: var(--button-font-size);
  padding: var(--button-padding);

  text-transform: uppercase;
  text-align: center;
  position: relative;
  cursor: pointer;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  display: block;
  text-decoration: none;
  border: none;
  width: 100%;
  transition: all 0.3s ease;

  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
  }

  &:hover {
    box-shadow: var(--button-box-shadow);
  }

  &--auto {
    width: auto;
    display: inline-block;
  }

  &--disabled {
    background: var(--button-background-color-disabled);
    color: var(--button-text-color-disabled);
    cursor: default;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }

  &--ghost,
  &--tertiary-active {
    border: solid 2px var(--button-background-color) !important;
    background: transparent;
    color: var(--button-ghost-text-color);
    box-shadow: none;
    padding: var(--button-ghost-padding);
    &:hover {
      box-shadow: none;
    }
  }

  &--secondary {
    color: var(--button-text-color-dark);
    background-color: var(--button-background-color);
    background-image: none;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }

  &--tertiary {
    color: var(--text-color-high);
    background: 0;
    border: solid 2px var(--button-background-color-disabled);
    background-color: var(--button-background-color-disabled);
    box-shadow: none;
    padding: var(--button-ghost-padding);
    &:hover {
      box-shadow: none;
    }
  }

  &--invisible {
    visibility: hidden;
  }

  &--hero {
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 32px;
  }

  &--inactive {
    cursor: default !important;
    pointer-events: none !important;
    &:hover {
      box-shadow: none;
    }
  }
}
