.top-container {
  --background-position-index: top;
  border-radius: var(--top-container-border-radius);

  @include breakpoint('tablet') {
    border-radius: var(--border-radius);
    padding-top: var(--padding);
  }
}

.page-index {
  .mainHeadline {
    margin-bottom: 0;
    @include breakpoint('tablet') {
      margin-bottom: 16px;
    }
  }

  .raffle-winner {
    padding: 0 16px;
    text-align: center;
    strong {
      font-weight: 700;
    }
  }
  .raffle-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: var(--padding);
    border-radius: 16px;
    background-color: rgba(97, 66, 133, 0.7);
    color: var(--color-neutral-100);
    @include breakpoint('desktop') {
      max-width: 400px;
      margin: 40px auto;
    }
  }

  .countdown-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 16px 0;
    border-radius: 16px 16px 0 0;
  }

  .raffle-hint {
    color: var(--countdown-label-color);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  .button-container {
    padding: 0 var(--padding);
    display: flex;
    align-items: flex-end;
    gap: 16px;

    .button {
      height: 128px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .chance {
    &-wrapper {
      background-color: rgba(255, 255, 255, 0.15);
      padding: 16px;
      border-radius: 0 0 16px 16px;
      color: var(--text-primary-inverse);
    }
    max-width: 290px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--missing {
      flex-flow: column;
      align-items: flex-start;
    }
    a {
      font-weight: bold;
    }
  }

  .chance__toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;
  }

  .newsletter-checkbox {
    display: flex;
  }

  .chance__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;

    counter-badge {
      min-width: 56px;
      display: inline-block;
    }
  }

  .chance__hint {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }

  .chance__prize {
    height: 128px;
    min-width: 96px;
    border-radius: 16px;
    background-color: var(--color-background-container-2);
    position: relative;

    &-link {
      color: var(--text-primary);
    }
    @include breakpoint('mobile-medium') {
      min-width: 128px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }

    span {
      position: absolute;
      width: 100%;
      text-align: center;
      background-color: rgba(44, 48, 58, 0.5);
      padding: 4px 4px;
      font-size: 10px;
      border-radius: 0 0 16px 16px;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }

  .bonus {
    --bonus-badge-size: 40px;
    margin-left: 16px;
    margin-top: 4px;
  }
}
