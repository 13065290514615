.game-list {
  display: grid;
  --gap: 16px;
  --width: calc(50% - var(--gap) / 2);
  --tablet-width: calc(33% - var(--gap) / 2);
  grid-template-columns: var(--width) var(--width);
  column-gap: var(--gap);
  row-gap: 24px;

  &-voting {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    scale: 0.6;
    transform-origin: left center;
    gap: 16px;
    color: var(--text-secomdary);
    position: relative;
    top: -8px;

    &-vote {
      white-space: nowrap;
    }
  }

  @include breakpoint('tablet') {
    grid-template-columns: var(--tablet-width) var(--tablet-width) var(--tablet-width);
  }
}

.game-list__item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  background-image: url('../images/loader.svg');
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: center 27%;

  img {
    display: block;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    border-radius: 16px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: var(--color-neutral-100);
  }

  .coinbadge {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}
