.inline-message {
  border-radius: 16px;
  background-color: var(--color-background-container-3);
  padding: 16px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  text-align: left;

  &-wrapper {
    padding: 0 16px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 8px;
    height: 100%;
    background-color: var(--color-info);
  }
}
.inline-message--error:after {
  background-color: var(--color-error);
}
.inline-message--success:after {
  background-color: var(--color-success);
}
.inline-message--warning:after {
  background-color: var(--color-warning);
}
