$mobile-medium: 360px;
$mobile-large: 599px;
$tablet: 600px;
$tablet-large: 768px;
$desktop: 840px;
$desktop-small: 900px;
$desktop-medium: 1024px;
$desktop-large: 1280px;
$desktop-xxl: 1490px;

$breakpoints: (
        'mobile-medium': $mobile-medium,
        'mobile-large': $mobile-large,
        'tablet': $tablet,
        'tablet-large': $tablet-large,
        'desktop': $desktop,
        'desktop-small': $desktop-small,
        'desktop-medium': $desktop-medium,
        'desktop-large': $desktop-large,
        'desktop-xxl': $desktop-xxl,
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin breakpoint-tablet-and-above {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin breakpoint-mobile-only {
  @media (max-width: $mobile-large) {
    @content;
  }
}
